import { createContext, useContext } from "react";
import {
  default as NextApp,
  AppProps as NextAppProps,
  AppContext as NextAppContext,
  AppInitialProps as NextAppInitialProps,
} from "next/app";
import { parseCookies } from "nookies";
import jwtDecode from "jwt-decode";

interface AuthProps {
  isLoggedIn?: boolean;
  hasPaywallAccess?: boolean;
}

type AppProps = NextAppProps & AuthProps;

type AppInitialProps = NextAppInitialProps & AuthProps;

type ProviderProps = AuthProps & {
  children: React.ReactNode;
};

const AuthContext = createContext<AuthProps>({});

export const AuthContextProvider = ({
  isLoggedIn,
  hasPaywallAccess,
  children,
}: ProviderProps) => {
  return (
    <AuthContext.Provider value={{ isLoggedIn, hasPaywallAccess }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined)
    throw new Error("useContext must be used within a Provider");
  return context;
};

export const withAuthContext = (App: React.FunctionComponent<NextAppProps>) => {
  /**
   * A Higher Order Component (HOC) that wraps our `App` with an auth context
   * for login and subscription state
   */
  const WithApp = ({ isLoggedIn, hasPaywallAccess, ...appProps }: AppProps) => {
    return (
      <AuthContextProvider
        isLoggedIn={isLoggedIn}
        hasPaywallAccess={hasPaywallAccess}
      >
        <App {...appProps} />
      </AuthContextProvider>
    );
  };

  WithApp.getInitialProps = async (
    ctx: NextAppContext
  ): Promise<AppInitialProps> => {
    // Vary the cache based on login and subscription status. These headers are appended by Fastly.
    ctx.ctx.res?.setHeader("Vary", "X-JWT-State, X-JWT-PaymeterAccess");

    // Get the Next App's initial props, we always need to return these
    const appProps = await NextApp.getInitialProps(ctx);

    // Parse the atljwt cookie on the server and the client, if it doesn't exist
    // we assume the user is neither logged in nor has a subscription
    const { atljwt } = parseCookies(ctx.ctx);

    if (!atljwt) {
      return {
        isLoggedIn: false,
        hasPaywallAccess: false,
        ...appProps,
      };
    }

    // Decode the JWT token for the existence of `paymeter_access`.
    // If the token exists, we assume the user is logged in
    const jwt = jwtDecode<{ paymeter_access: boolean }>(atljwt);
    return {
      isLoggedIn: true,
      hasPaywallAccess: jwt.paymeter_access,
      ...appProps,
    };
  };

  return WithApp;
};
